// rollover
let preLoadImg = new Object();
 
function initRollOvers(){
  $("img.imgover,input.imgover").each(function(){
    let imgSrc = this.src;
    let sep = imgSrc.lastIndexOf('.');
    let onSrc = imgSrc.substr(0, sep) + '_o' + imgSrc.substr(sep, 4);
    preLoadImg[imgSrc] = new Image();
    preLoadImg[imgSrc].src = onSrc;
    $(this).hover(
      function() { this.src = onSrc; },
      function() { this.src = imgSrc; }
    );
  });
}
$(function(){
  initRollOvers();
});


// smoothScroll
jQuery.easing.quart = function (x, t, b, c, d) {
  return -c * ((t=t/d-1)*t*t*t - 1) + b;
};

jQuery(document).ready(function(){
  jQuery('a[href*="#"]').click(function() {    
    let targetId = jQuery(this.hash);
    console.log(targetId);
    
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      let target = jQuery(this.hash);
      target = target.length && target || jQuery('[name=' + this.hash.slice(1) +']');

      // ターゲット先へスクロール移動
      if (target.length) {
        let targetOffset = target.offset().top;
        jQuery('html,body').animate({ scrollTop: targetOffset }, 1200, 'swing');

        // ターゲット先がスライドパネルの場合はオープン
        if (target.next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
        } else {
          target.next(".js-slideBox").find(".js-slideBtn").addClass('is-active');
          target.next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
        }

        return false;
      }
    }
  });
});


//画像のロード完了後にアニメーションを実行する
//$(window).on("load", function(){
//  $(".js-frontkvanimeitem").addClass('-startanime');
//});


// slide btn
$(function() {
  $(".js-slideBtn").each(function(index,element) {
    $(element).click(function() {
      $(this).toggleClass('is-active');
      $(element).next(".js-slidePanel").slideToggle(300);
    });
  });

  // ハッシュがあるときはオープン
  if (window.location.hash.length>0) {
    if ($(window.location.hash).next(".js-slideBox").find(".js-slideBtn").hasClass('is-active')) {
    } else {
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").toggleClass('is-active');
      $(window.location.hash).next(".js-slideBox").find(".js-slideBtn").next(".js-slidePanel").slideToggle(300);
    }
  }
});


// current
$(function() {
  let gNavItem = $("#gnav .js-gnavItem");

  let local_path_arr = $(location).attr('pathname').split('/');
  let local_dir = local_path_arr[1];

  gNavItem.each(function(){
    let href_path = $(this).attr("href");
    let href_path_arr = href_path.split('/');
    let href_dir = href_path_arr[1];
    
    if(href_dir == local_dir){
      $(this).addClass('is-current');
    } else {
      $(this).removeClass('is-current');
    }
  });
});


// header scroll settings
$(window).on('load scroll resize', function(){
  let headerPosTop = 30;  //ヘッダーの初期位置
  
  let winW = $(window).width();
  let winscrW = window.innerWidth;  /* スクロールバーを含む幅 */
  let hd = $('#header');
  let hdbg = $('#headerbg');
  let contentTop = $('#hero');
  let gnav = $('#gnavfix');
  let logo = $('#js-hdlogo');
  let gnavBtns = $('#ganvBtn');
  let scroll = $(window).scrollTop();
  let kvNextContentPos = contentTop.outerHeight();  //KVの高さ
  let hdbgH = hdbg.outerHeight();  //header背景の高さ
  let headerbgAjustPos = kvNextContentPos - hdbgH - 60;     //header背景変化開始ポジション
  let hdbgOpacity = 1;

  if ( scroll == 0 && winscrW <= 799 ) {
    hd.removeClass('js-isscroll');
    hd.addClass('js-noscroll');
  } else if( scroll < headerPosTop && winscrW > 799 ) {
    hd.removeClass('js-isscroll');
    hd.addClass('js-noscroll');
  } else if ( winscrW > 799 ) {
    hd.removeClass('js-noscroll');
    hd.addClass('js-isscroll');
  } else {
    hd.removeClass('js-noscroll');
    hd.addClass('js-isscroll');
  }
  
  //トップページのみ、header背景表示制御
  if ( $(".l-wrap").hasClass("-front") ) {
    if ( scroll >= headerbgAjustPos && winscrW >= 800 ) {
      hdbgOpacity = 1 - ( (scroll - headerbgAjustPos) * 0.01 );
      
      if (0 <= hdbgOpacity && hdbgOpacity <= 1) {
        hdbg.css({opacity: hdbgOpacity});
      } else if (0 > hdbgOpacity) {
        hdbg.css({opacity: 0});
      }
    } else {
      hdbg.css({opacity: 1});
    }
  }
});


// バーガーメニュー開閉
$(function(){
  let winW = $(window).width();
  let winscrW = window.innerWidth; //スクロールバーを含む幅 

  $("#js-drawerOpen").click(function() {
    if ($("#js-drawerOpen").hasClass('is-open')) {
      $("#js-drawerOpen").removeClass('is-open');
      $("#drawer").removeClass('is-open');
      $("#gnav").removeClass('is-open');
      $("#dnav").removeClass('is-open');
      $("#js-drawerOpen").addClass('is-close');
      $("#drawer").addClass('is-close');
      $("#gnav").addClass('is-close');
      $("#dnav").addClass('is-close');
    } else {
      $("#js-drawerOpen").removeClass('is-close');
      $("#drawer").removeClass('is-close');
      $("#gnav").removeClass('is-close');
      $("#dnav").removeClass('is-close');
      $("#js-drawerOpen").addClass('is-open');
      $("#drawer").addClass('is-open');
      $("#gnav").addClass('is-open');
      $("#dnav").addClass('is-open');
    }
  });
});

$(window).on('load scroll resize', function () {
  let winW = $(window).width();
  let winscrW = window.innerWidth; //スクロールバーを含む幅 

  // 800px以上時は、is-openとis-closeのclassを削除する
  if ( winscrW >= 800 ) {
    $("#js-drawerOpen").removeClass('is-open');
    $("#drawer").removeClass('is-open');
    $("#gnav").removeClass('is-open');
    $("#dnav").removeClass('is-open');
    $("#js-drawerOpen").removeClass('is-close');
    $("#drawer").removeClass('is-close');
    $("#gnav").removeClass('is-close');
    $("#dnav").removeClass('is-close');
  }
});



// footer padding
/*
$(window).on('load scroll resize', function(){
  let footer = $('footer');
  let fixnavH = $('#fixBtmNav').outerHeight(true);
  footer.css({'padding-bottom' : fixnavH });
});
*/


//fadein
$(window).scroll(function (){
  $('.js-fadeIn').each(function(){
    let elemPos = $(this).offset().top;
    let scroll = $(window).scrollTop();
    let windowHeight = $(window).height();
    if (scroll > elemPos - windowHeight - 30){
        $(this).addClass('js-scrollIn');
    }
  });
});


$(window).on('load scroll resize', function () {
  let winW = $(window).width();
  let winscrW = window.innerWidth; //スクロールバーを含む幅 

  let winH = $(window).height(); //ウィンドウの高さ

  let scroll = $(window).scrollTop();
  let fadeinitem = $('.js-fadeinitem');
  let fadeindownitem = $('.js-fadeindownitem');
  
  //要素フェードイン
  fadeinitem.each(function () {
    let fiiTop = $(this).offset().top; // 要素が画面の上から80%(下から20%)に来た時に動く

    let fadeinitemPos = fiiTop - winH * 0.8;

    if (scroll > fadeinitemPos) {
      $(this).addClass("js-fadein");
    }
  });
  
  //要素フェードイン&ダウン
  fadeindownitem.each(function () {
    let fidiTop = $(this).offset().top; // 要素が画面の上から80%(下から20%)に来た時に動く

    let fadeindownitemPos = fidiTop - winH * 0.8;

    if (scroll > fadeindownitemPos) {
      $(this).addClass("js-fadeindown");
    }
  });
});


// 電話番号リンク
if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
  $(function() {
    $('.js-tellink').each(function() {
      let str = $(this).html();

      if ($(this).children().is('img')) {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      } else {
        $(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/[^0-9]/g, '')).append(str + '</a>')); //対象文字列内から数字だけ取り出してhrefにセット
      }
    });
  });
}
